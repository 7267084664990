import { default as ai_45form_45builderuIkzU6iSe5Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/ai-form-builder.vue?macro=true";
import { default as editpo5c0EvcyWMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexRJeWGdgDynMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexK5T6s8fXRtMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexR1ftrFclt3Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharepapsovcylZMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as stats7vJpaCGtIrMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionsiGzfai3hmnMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as show9ENCXNfK62Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestGDSuwiSyvpMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/create/guest.vue?macro=true";
import { default as indexMmBpuYbyLKMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/create/index.vue?macro=true";
import { default as homebqDcJw1qR2Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/home.vue?macro=true";
import { default as indexesEZzfbOVQMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/index.vue?macro=true";
import { default as loginqy7193XxLIMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/login.vue?macro=true";
import { default as callback4f76QX4KpNMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/oauth/callback.vue?macro=true";
import { default as emailPgnBR16WXCMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/password/email.vue?macro=true";
import { default as _91token_93j0ixjpCAU7Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingp7n1epmSUOMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/pricing.vue?macro=true";
import { default as privacy_45policyl7zqLlMtmsMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/privacy-policy.vue?macro=true";
import { default as registerXXyFJ7pKCfMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/register.vue?macro=true";
import { default as access_45tokensoejjdJ8KZDMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountmiZWz4XZtBMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/account.vue?macro=true";
import { default as adminQzTJyACLzQMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/admin.vue?macro=true";
import { default as billingNk8xLyCcJaMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/billing.vue?macro=true";
import { default as connectionspz2dELCQtRMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/connections.vue?macro=true";
import { default as index5necjOzMckMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/index.vue?macro=true";
import { default as passwordAHXvNfkIoaMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/password.vue?macro=true";
import { default as profileRUDOrQxni6Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/profile.vue?macro=true";
import { default as workspacex5eH5q2QW1Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/workspace.vue?macro=true";
import { default as settingsrOifMB8CjnMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/settings.vue?macro=true";
import { default as erroryeyahdi6VXMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/subscriptions/error.vue?macro=true";
import { default as successIubgqPeYjGMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93WJDkqZKzqvMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/[slug].vue?macro=true";
import { default as indexA5fu4on282Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/index.vue?macro=true";
import { default as _91slug_93uNA0jaGfnUMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesYGtZGhyt4PMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93IJpabt4DRvMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsOfiN2jDLn0Meta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsPXdLrurnBkMeta } from "/codebuild/output/src3609651868/src/OpnForm/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderuIkzU6iSe5Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editpo5c0EvcyWMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: show9ENCXNfK62Meta?.name,
    path: "/forms/:slug()/show",
    meta: show9ENCXNfK62Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexK5T6s8fXRtMeta?.redirect,
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexR1ftrFclt3Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharepapsovcylZMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: stats7vJpaCGtIrMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionsiGzfai3hmnMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestGDSuwiSyvpMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexMmBpuYbyLKMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homebqDcJw1qR2Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginqy7193XxLIMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: emailPgnBR16WXCMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93j0ixjpCAU7Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingp7n1epmSUOMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policyl7zqLlMtmsMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerXXyFJ7pKCfMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingsrOifMB8CjnMeta?.name,
    path: "/settings",
    meta: settingsrOifMB8CjnMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountmiZWz4XZtBMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminQzTJyACLzQMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billingNk8xLyCcJaMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionspz2dELCQtRMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: index5necjOzMckMeta?.redirect,
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordAHXvNfkIoaMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileRUDOrQxni6Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspacex5eH5q2QW1Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: erroryeyahdi6VXMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successIubgqPeYjGMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesYGtZGhyt4PMeta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsOfiN2jDLn0Meta || {},
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/codebuild/output/src3609651868/src/OpnForm/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]